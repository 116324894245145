<template>
  <v-container fluid>
    <filter-panel
      :users="users"
      :loading="loading"
      @clear-filters="clearFilters"
      @apply-filters="applyFilters"
    />
    <v-card class="mt-4">
      <v-data-table
        :headers="headers"
        :items="approvalDecisions"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :search="search"
        class="elevation-1"
      >
        <!-- <template v-slot:item.actions="{ item }">
          <v-icon color="primary" @click="viewItem(item)">mdi-eye</v-icon>
        </template> -->
        <template v-slot:item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ formatDate(item.updated_at) }}
        </template>
        <template v-slot:item.Status="{ item }">
          <v-chip :color="getStatusColor(item.Status)" text-color="white" small>
            {{ getStatusName(item.Status) }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" @click="viewItem(item)">mdi-eye</v-icon>
        </template>
      </v-data-table>
      <view-approval-item-modal v-model="viewDialog" :item="selectedItem" />
    </v-card>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import FilterPanel from "../_components/FilterPanel.vue";
import ViewApprovalItemModal from "../_components/ViewItem.vue";
import { debounce } from "lodash";
import moment from "moment";

export default {
  components: {
    FilterPanel,
    ViewApprovalItemModal,
  },
  props: {
    status: {
      type: String,
      required: true,
      validator: (value) => ["pending", "approved", "rejected"].includes(value),
    },
  },
  data() {
    return {
      selectedItems: [],
      viewDialog: false,
      search: "",
      loading: false,
      users: [],
      selectedItem: null,
      totalItems: 0,
      approvalDecisions: [],
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ["id"],
        sortDesc: [false],
      },
      headers: [
        { text: "#", value: "id" },
        { text: "Doc No.", value: "docs_confirmation.DraftEntry" },
        {
          text: "Doc Type",
          value: "docs_confirmation.objecttype.DocumentName",
        },
        { text: "Originator", value: "docs_confirmation.originator.name" },
        { text: "Approver", value: "user.name" },
        { text: "Stage", value: "stage.Name" },
        { text: "Answer", value: "Status" },
        { text: "Remarks", value: "Remarks" },
        { text: "Draft Update Date", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      filters: {},
    };
  },
  methods: {
    debouncedSearch: debounce(function () {
      this.fetchData();
    }, 500),
    formatDate(date) {
      return moment(date).format("LLL");
    },
    getStatusName(status) {
      const statusMap = {
        Y: "Approved",
        N: "Rejected",
        W: "Pending",
      };
      return statusMap[status] || status;
    },
    getStatusColor(status) {
      const colorMap = {
        Y: "success",
        N: "error",
        W: "warning",
      };
      return colorMap[status] || "grey";
    },
    fetchData() {
      this.loading = true;
      const params = new URLSearchParams({
        ...this.options,
        ...this.filters,
        status: "Y",
      });

      this.$store
        .dispatch("get", `/ApprovalService_DecisionReport?${params.toString()}`)
        .then((res) => {
          this.approvalDecisions = res.ResponseData.data;
          this.totalItems = res.ResponseData.total;
          this.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "error");
          this.loading = false;
        });
    },
    clearFilters() {
      this.filters = {};
      this.fetchData();
    },
    applyFilters(newFilters) {
      this.filters = newFilters;
      this.options.page = 1; // Reset to first page when applying new filters
      this.fetchData();
    },
    viewItem(item) {
      this.selectedItem = item;
      this.viewDialog = true;
    },
    getUsers() {
      this.$store
        .dispatch("get", "/users")
        .then((res) => {
          this.users = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "error");
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
    status() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
    this.getUsers();
  },
};
</script>
